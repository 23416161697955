<template>
  <div class="dashboard">
    <!--    <div class="dashboard-text">name: {{ name }}</div>-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Dashboard',
  computed: {
    ...mapGetters([
      'name'
    ])
  }
};
</script>

<style scoped>
.dashboard {
  width: 100%;
  height: calc(100vh - 150px);
  background: url(~@/assets/home.png) no-repeat center center / auto 60%;
}
</style>
